<script setup>
import { onUnmounted, ref, watch } from "vue";
import { fbauth } from "@/firebase";
import axios from "axios";
import { config } from "@/config";

const kartons = ref([]);
const searchQuery = ref('');
const filteredKartons = ref([]);
let authToken = null;

const fetchData = async () => {
  const auth = fbauth;

  if (auth.currentUser) {
    try {
      authToken = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.apiUrl}/umzug/kartons`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        withCredentials: true
      });

      kartons.value = response.data;
      filterKartons();

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User is not authenticated");
  }
};

const highlightText = (text, query) => {
  if (!query) return text; // If no query, return original text
  const regex = new RegExp(`(${query})`, 'gi'); // Case-insensitive matching
  return text.replace(regex, '<mark>$1</mark>'); // Wrap matched text in <mark>
};

const filterKartons = () => {
  if (!searchQuery.value) {
    filteredKartons.value = kartons.value.map(karton => ({ ...karton, highlighted: karton }));
    return;
  }

  const query = searchQuery.value.toLowerCase();
  filteredKartons.value = kartons.value
    .filter(karton =>
      karton.Bezeichnung?.toLowerCase().includes(query) ||
      karton.Bezeichnung_Ziel?.toLowerCase().includes(query) ||
      karton.Ziel?.toLowerCase().includes(query) ||
      karton.Inhalt?.toLowerCase().includes(query)
    )
    .map(karton => ({
      ...karton,
      highlighted: {
        Bezeichnung: highlightText(karton.Bezeichnung || '', searchQuery.value),
        Bezeichnung_Ziel: highlightText(karton.Bezeichnung_Ziel || '', searchQuery.value),
        Ziel: highlightText(karton.Ziel || '', searchQuery.value),
        Inhalt: highlightText(karton.Inhalt || '', searchQuery.value),
      },
    }));
};

watch(searchQuery, () => {
  filterKartons();
});

const unsubscribe = fbauth.onAuthStateChanged(() => {
  fetchData();
});

onUnmounted(() => {
  unsubscribe();
});
</script>

<template>
  <div>
    <h2>Umzug</h2>

    <!-- Search Input -->
    <div class="mb-3">
      <div class="input-group">
        <span class="input-group-text">
          <i class="bi bi-search"></i>
        </span>
        <input
          type="text"
          class="form-control"
          v-model="searchQuery"
          placeholder="Suche nach Bezeichnung, Ziel, Bezeichnung Ziel oder Inhalt..."
        >
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-responsive table-hover table-sm">
        <thead>
        <tr>
          <th scope="col">Nummer</th>
          <th scope="col">Bezeichnung</th>
          <th scope="col">Ziel</th>
          <th scope="col">Bezeichnung Ziel</th>
          <th scope="col">Inhalt</th>
          <th scope="col">Noch Platz</th>
          <th scope="col">Beschriftet</th>
          <th scope="col">Bemerkung</th>
          <th scope="col">Gedruckt</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(karton, index) in filteredKartons" :key="index">
          <td>{{ karton.Nummer }}</td>
          <td v-html="karton.highlighted?.Bezeichnung || karton.Bezeichnung"></td>
          <td v-html="karton.highlighted?.Ziel || karton.Ziel"></td>
          <td v-html="karton.highlighted?.Bezeichnung_Ziel || karton.Bezeichnung_Ziel"></td>
          <td v-html="karton.highlighted?.Inhalt || karton.Inhalt"></td>
          <td>{{ karton.NochPlatz }}</td>
          <td>{{ karton.Beschriftet }}</td>
          <td>{{ karton.Bemerkung }}</td>
          <td>{{ karton.Gedruckt }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="scss">
.input-group {
  max-width: 500px;
}
</style>